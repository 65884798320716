import { ButtonPermissionMap } from '../../constant/ButtonPermission'
export default [
  {
    name: 'introduction',
    path: '/introduction',
    meta: {
      permission: ButtonPermissionMap.Introduction
    },
    component: () =>
      import(/* webpackChunkName: "introduction" */ './Introduction.vue')
  },
  {
    name: 'password',
    path: '/password',
    component: () =>
      import(/* webpackChunkName: "password" */ './ChangePassword.vue')
  },
  {
    name: 'help',
    path: '/help',
    meta: {
      permission: ButtonPermissionMap.Help
    },
    component: () => import(/* webpackChunkName: "help" */ './Help.vue')
  },
  {
    name: 'EmergencyContact',
    path: '/emergency_contact',
    component: () =>
      import(
        /* webpackChunkName: "emergency_contact" */ './EmergencyContact.vue'
      )
  },
  {
    name: 'RegisterMobile',
    path: '/register_mobile',
    component: () =>
      import(/* webpackChunkName: "register_mobile" */ './RegisterMobile.vue')
  },
  {
    name: 'AccountCancel',
    path: '/account_cancel',
    component: () =>
      import(/* webpackChunkName: "account_cancel" */ './AccountCancel.vue')
  }
]
