import api from '@/api'
export default {
  namespaced: true,
  state: {
    projectId: '',
    loadingId: false
  },
  getters: {},
  actions: {
    async fetchProjectId(context) {
      if (context.state.projectId) return
      if (context.state.loadingId) return
      context.commit('updateLoadingId', true)
      let res = await api.post({
        url: 'merApi/houseRental/rules/mgmt/queryDuhdMrchnt',
        data: {
          custId: context.rootState.user.userInfo.custId
        }
      })
      context.commit('updateLoadingId', false)
      if (res) {
        let dataList = res.dataList || []
        let projectId = dataList[0]?.projectId
        context.commit('updateProjectId', projectId)
      }
    }
  },
  mutations: {
    updateLoadingId(state, data) {
      state.loadingId = data
    },
    updateProjectId(state, projectId) {
      state.projectId = projectId
    }
  }
}
