import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

function loadModules() {
  const context = require.context('./modules', true, /\.js$/)
  const modules = context.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = context(modulePath)
    modules[moduleName] = value.default
    return modules
  }, {})
  return {
    context,
    modules
  }
}
const { modules } = loadModules()
const plugins = []
if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger())
}
const store = new Vuex.Store({
  modules,
  plugins
})

export default store
