export default [
  {
    name: 'MerchantGoldPaymentManage',
    path: 'gold/payment_manage',
    component: () =>
      import(
        /* webpackChunkName: "gold" */ '@/projects/operation/views/Gold/PaymentManage.vue'
      )
  },
  {
    name: 'MerchantGoldExchangeList',
    path: 'gold/exchange/list',
    component: () =>
      import(
        /* webpackChunkName: "gold" */ '@/projects/operation/views/Gold/GoldExchangeList.vue'
      )
  }
]
