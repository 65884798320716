import Vue from 'vue'
import '@/assets/iconfont/iconfont.css'
import './initComponent'
import store from './store/index'
import i18n from '@/i18n'
import App from './App.vue'
import router from './router'
import { initCommonRouter } from '@/util/commonRouter'
Vue.config.productionTip = false
Vue.prototype.$commonRouter = initCommonRouter(router)
new Vue({
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
