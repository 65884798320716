export const ButtonPermissionMap = {
  ExportTask: 'MB0000001',
  Help: 'MB0000002',
  Introduction: 'MB0000003',
  LoginHistory: 'MB0000004',
  OrderManagement_MyOrderList_Export: 'MB0000005',
  OrderManagement_MyOrderList_Detail: 'MB0000006',
  AccountManagement_MyAccountList_Detail: 'MB0000007',
  AccountManagement_MyAccountList_PaymentHistory: 'MB0000008',
  AccountManagement_AccountHistory_Export: 'MB0000009',
  AccountManagement_AccountHistory_Detail: 'MB0000010',
  POSManagement_Collect: 'MB0000011',
  POSManagement_NewPOS: 'MB0000012',
  POSManagement_Detail: 'MB0000013',
  POSManagement_Edit: 'MB0000014',
  POSManagement_Active: 'MB0000015',
  POSManagement_Inactive: 'MB0000016',
  POSManagement_Delete: 'MB0000017',
  CashInOut_CashIn: 'MB0000018',
  CashInOut_CashOut: 'MB0000019',
  NewPromotion: 'MB0000020',
  PromotionManagement_Export: 'MB0000021',
  PromotionManagement_Applied: 'MB0000022',
  PromotionManagement_Used: 'MB0000023',
  PromotionManagement_Detail: 'MB0000024',
  PromotionManagement_Statistics: 'MB0000025',
  PromotionManagement_Stop: 'MB0000054',
  GoodsManagement_GoodsRelease: 'MB0000026',
  GoodsManagement_NewGoods: 'MB0000027',
  GoodsManagement_CommentsDetail: 'MB0000028',
  GoodsManagement_View: 'MB0000029',
  GoodsManagement_Offline: 'MB0000030',
  GoodsManagement_Online: 'MB0000031',
  GoodsManagement_Edit: 'MB0000032',
  GoodsManagement_Delete: 'MB0000033',
  RestaurantManagement_Order_Detail: 'MB0000067',
  RestaurantManagement_DiningManagement_DiningDetail: 'MB0000034',
  RestaurantManagement_DiningManagement_PrintMenu: 'MB0000035',
  RestaurantManagement_DiningManagement_OrderSettlement: 'MB0000036',
  RestaurantManagement_DiningManagement_EndingDining: 'MB0000053',
  RestaurantManagement_DiningManagement_CancelDining: 'MB0000037',
  RestaurantManagement_TableManagement_EditRegion: 'MB0000038',
  RestaurantManagement_TableManagement_New: 'MB0000039',
  RestaurantManagement_TableManagement_DownloadAll: 'MB0000040',
  RestaurantManagement_FoodsManagement_EditFoodsCategory: 'MB0000041',
  RestaurantManagement_FoodsManagement_NewGoods: 'MB0000042',
  RestaurantManagement_FoodsManagement_View: 'MB0000043',
  RestaurantManagement_FoodsManagement_Offline: 'MB0000044',
  RestaurantManagement_FoodsManagement_Online: 'MB0000045',
  RestaurantManagement_FoodsManagement_Edit: 'MB0000046',
  RestaurantManagement_FoodsManagement_Delete: 'MB0000047',
  Message_Detail: 'MB0000048',
  Message_Delete: 'MB0000049',
  CommentsManagement_Like: 'MB0000050',
  CommentsManagement_Reply: 'MB0000051',
  // MembershipManagement_Detail: 'MB0000052'
  Department_Staff_New: 'MB0000055',
  Department_Staff_Edit: 'MB0000056',
  Department_Staff_Delete: 'MB0000057',
  Department_StaffManagement_New: 'MB0000058',
  Department_StaffManagement_Detail: 'MB0000059',
  Department_StaffManagement_Edit: 'MB0000060',
  Department_StaffManagement_DisableAndEnable: 'MB0000061',
  Department_StaffManagement_ResetPassword: 'MB0000062',
  Department_StaffManagement_Delete: 'MB0000063',
  RevenueManagement_Exoprt: 'MB0000064',
  RevenueManagement_Detail: 'MB0000065',
  Merchant_RentalBillManagement_ImportWaterElectric: 'MB0000068',
  Merchant_RentalBillManagement_ImportWaterElectricHistory: 'MB0000069',
  Merchant_RentalBillManagement_Export: 'MB0000070',
  Merchant_RentalBillManagement_Detail: 'MB0000071',
  Merchant_RentalBillManagement_CashPayment: 'MB0000072',
  Merchant_RentalContractManage_Termination: 'MB0000073',
  Merchant_RentalContractManage_Edit: 'MB0000074',
  Merchant_RentalContractManage_Detail: 'MB0000075',
  Merchant_RentalContractManage_ImportHistory: 'MB0000076',
  Merchant_RentalContractManage_ImportContract: 'MB0000077',
  Merchant_RentalContractManage_Download: 'MB0000078',
  Merchant_RentalContractManage_FastContract: 'MB0000079',
  Merchant_RentalContractManage_FastContract: 'MB0000079',
  Merchant_RentalPaymentManage_Export: 'MB0000081',
  Merchant_RentalPaymentManage_Detail: 'MB0000080',
  Merchant_RentalPaymentManage_CashPayment: 'MB0000082',
  Merchant_RentalBillManagement_Exempted: 'MB0000083',
  Merchant_GoldManagementPaymentManagement_Export: 'MB0000084'
}
