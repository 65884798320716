import '@/i18n/template/projects/merchant/store/modules/user.js'
import api, { setCbbHeader } from '@/api/index'
import { Message } from 'element-ui'
import { setAuthData, clearAuthData, onAuthDateRefresh } from '@/api/tokenApi'
import { CONST_FLAG_SORT_NBR } from '@/constant'
import i18n from '@/i18n'
let initalAuthData = localStorage.authData || ''
let userInfo = {}
let initalAvatar = localStorage.avatar || ''
if (initalAvatar === 'null') {
  initalAvatar = ''
}
if (initalAuthData) {
  try {
    initalAuthData = JSON.parse(initalAuthData)
    if (initalAuthData.expires_time > Date.now()) {
      setAuthData(initalAuthData)
      userInfo = initalAuthData.userInfo || {}
      setCbbHeader({
        custId: userInfo.custId,
        custRprsnId: userInfo.custRprsnId,
        staffId: 'MB000000',
        userGrpCd: userInfo.userGrpCd,
        chnlDscd: '03'
      })
    }
  } catch (e) {
    userInfo = {}
  }
}

onAuthDateRefresh(newAuthData => {
  try {
    let oldAuth = JSON.parse(localStorage.authData)
    localStorage.authData = JSON.stringify({
      ...oldAuth,
      access_token: newAuthData.access_token,
      refresh_token: newAuthData.refresh_token,
      user_secret: newAuthData.user_secret,
      expires_in: newAuthData.expires_in,
      expires_time: newAuthData.expires_time
    })
  } catch (e) {}
})

export default {
  namespaced: true,
  state: {
    login: !!userInfo.custId,
    userInfo: userInfo,
    // null | string[] 01 普通商户 02 cash counter 04 工资代发  05  餐饮Restaurant
    permissionScope: null,
    avatar: initalAvatar,
    posAccountList: [],
    accountList: [],
    realnameData: null,
    isMltplDept: false // 是否是大商户多级部门
  },
  getters: {
    isGeneralMerchant(state) {
      let scope = state.permissionScope || []
      return scope.indexOf('01') !== -1
    },
    isCashCounter(state) {
      let scope = state.permissionScope || []
      return scope.indexOf('02') !== -1
    },
    isSalaryAgency(state) {
      let scope = state.permissionScope || []
      return scope.indexOf('04') !== -1
    },
    isRestaurant(state) {
      let scope = state.permissionScope || []
      return scope.indexOf('05') !== -1
    }
  },
  actions: {
    login(context, authData) {
      context.commit('login', authData.userInfo)
      localStorage.authData = JSON.stringify(authData)
      setAuthData(authData)
      setCbbHeader({
        custId: authData.userInfo.custId,
        staffId: 'MB000000',
        custRprsnId: authData.userInfo.custRprsnId,
        userGrpCd: '01',
        chnlDscd: '03'
      })
      context.dispatch('getAvatar', authData)
      return Promise.resolve(authData.userInfo)
    },
    logout(context, logoutType) {
      if (logoutType === 'expired' && context.state.login) {
        // 防重复提示
        Message.error(i18n.t('loginExpired'))
      }
      localStorage.removeItem('authData')
      localStorage.removeItem('avatar')
      clearAuthData()
      setCbbHeader()
      context.commit('logout')
    },
    async getAvatar(context) {
      let res = await api.requestCBB('SCU5010401', {
        CustIdntyImageIqrySvcIn: {
          custId: context.state.userInfo.custId
        }
      })
      if (res.CustLastIdntyImageIqrySvcOut) {
        let avatar = res.CustLastIdntyImageIqrySvcOut.fileUrl
        if (avatar === 'null') {
          avatar = ''
        }
        context.commit('setAvatar', res.CustLastIdntyImageIqrySvcOut.fileUrl)
      }
    },
    async getAvatarByCustId(context, custId) {
      let res = await api.requestCBB('SCU5010401', {
        CustIdntyImageIqrySvcIn: {
          custId: custId
        }
      })
      if (res.CustLastIdntyImageIqrySvcOut) {
        return res.CustLastIdntyImageIqrySvcOut.fileUrl
      }
    },
    async updateAvatar(context, { fileUrl, bucketNm, bucketKey }) {
      let res = await api.requestCBB('SCU5010101', {
        AtchmntFileMgmtSvcIn: {
          custId: context.state.userInfo.custId,
          fileUrl,
          atchmntFileId: '',
          bucketNm,
          bucketKey
        }
      })
      if (res.header && res.header.returnCode === '0') {
        context.commit('setAvatar', fileUrl)
      }
    },
    async getAccountList(context) {
      let res = await api.requestCBB('SCU1110401', {
        CustRelArrInqrySvcGetCustRelAcctListIn: {
          custId: context.state.userInfo.custId,
          custArrSrchCrtrCd: '1',
          arrBalAmtInqryYn: 'Y',
          crncyCdInqryYn: 'Y',
          bizDscd: '01',
          arrCustRelCd: '02'
        }
      })
      if (res) {
        const mainArrList =
          res.CustRelArrInqrySvcGetCustrelAcctListOut.mainArrList || []
        mainArrList.sort(function (left, right) {
          left.crncyCdWeight = CONST_FLAG_SORT_NBR[left.crncyCd] + left.acctNbr
          right.crncyCdWeight =
            CONST_FLAG_SORT_NBR[right.crncyCd] + right.acctNbr
          return left.crncyCdWeight - right.crncyCdWeight
        })
        context.commit('setAccountList', mainArrList)
        return mainArrList
      }
    },
    async getPosAccountList(context) {
      let res = await api.requestCBB('SCU1110401', {
        CustRelArrInqrySvcGetCustRelAcctListIn: {
          custId: context.state.userInfo.custId,
          custArrSrchCrtrCd: '1',
          arrBalAmtInqryYn: 'Y',
          crncyCdInqryYn: 'Y',
          bizDscd: '01',
          arrCustRelCd: '02',
          pdCd: '01011000100000000025'
        }
      })
      if (res) {
        const mainArrList =
          res.CustRelArrInqrySvcGetCustrelAcctListOut.mainArrList || []
        mainArrList.sort(function (left, right) {
          left.crncyCdWeight = CONST_FLAG_SORT_NBR[left.crncyCd] + left.acctNbr
          right.crncyCdWeight =
            CONST_FLAG_SORT_NBR[right.crncyCd] + right.acctNbr
          return left.crncyCdWeight - right.crncyCdWeight
        })
        context.commit('setPosAccountList', mainArrList)
        return mainArrList
      }
    },
    async getRealnameStatus(context) {
      return context.dispatch('getProfile')
    },
    async getProfile(context) {
      let res = await api.requestCBB('SCU1100401', {
        CustCmphInqrySvcGetCustOvrvwIn: {
          custId: context.state.userInfo.custId
        }
      })
      if (res.CustCmphInqrySvcGetCustOvrvwOut) {
        let data = res.CustCmphInqrySvcGetCustOvrvwOut
        let obj = {}
        let info = Array.isArray(data.xtnInfoList) ? data.xtnInfoList : []
        for (let item of info) {
          obj[item.xtnAtrbtNm] = item.xtnAtrbtCntnt
        }
        context.commit('setMltplDept', obj.mltplDept === 'Y')
        data.xtnInfo = obj
        let addrArr = data.cntctPntList
        if (Array.isArray(addrArr)) {
          for (let i of addrArr) {
            if (i.cntctMthdTpCd === '15') {
              data.address = i.dtlCntctPntCntnt
              break
            }
          }
        }
        data.businessLicense = data.businessLicense || ''
        data.businessLicense = data.businessLicense.trim()
        if (data.businessLicense === 'null') {
          data.businessLicense = ''
        }

        for (let item of data.cntctPntList) {
          if (item.cntctMthdTpCd == '15' && item.cntctPntTpCd == '01') {
            data.address = item.dtlCntctPntCntnt
          }
          if (item.cntctMthdTpCd == '01' && item.cntctPntTpCd == '01') {
            data.registerPhone = item.dtlCntctPntCntnt
            data.intrntnlNbr = item.intrntnlNbr
          }
        }
        context.commit('setRealnameData', data)
        return data
      }
    },
    async getPermissionScope(context) {
      let res = await api.requestCBB('SPY5130403', {
        MrchntArrMgmtSvcGetMrchntArrIn: {
          custId: context.state.userInfo.custId
        }
      })
      if (res.MrchntArrMgmtSvcGetMrchntArrOut) {
        context.commit(
          'setPermissionScope',
          res.MrchntArrMgmtSvcGetMrchntArrOut.mrchntRgstrnTpCdList
        )
      }
    }
  },
  mutations: {
    login(state, data) {
      state.login = true
      state.userInfo = data
    },
    logout(state) {
      state.login = false
      state.avatar = ''
      state.userInfo = {}
    },
    statusChange(state, data) {
      state.status = data
    },
    setAvatar(state, data) {
      state.avatar = data
      localStorage.avatar = data
    },
    setAccountList(state, data) {
      state.accountList = data
    },
    setPosAccountList(state, data) {
      state.posAccountList = data
    },
    setRealnameData(state, data) {
      state.realnameData = data
    },
    setPermissionScope(state, data) {
      state.permissionScope = data
    },
    setMltplDept(state, data) {
      state.isMltplDept = data
    }
  }
}
