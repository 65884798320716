import BillingReportYangon from '@/projects/operation/views/Billing/Rental/Report/Yangon/router'
import BillingReportNapPyiTaw from '@/projects/operation/views/Billing/Rental/Report/NayPyiTaw/router'
export default [
  {
    name: 'MerchantRentalContractManage',
    path: '/rental/contract_manage',
    component: () =>
      import(/* webpackChunkName: "rental " */ './RentalContractManage.vue')
  },
  {
    name: 'RentalBillManage ',
    path: '/rental/bill_manage',
    component: () =>
      import(/* webpackChunkName: "rental " */ './RentalBillManage.vue')
  },
  {
    path: '/rental/payment',
    name: 'MerchantRentalPaymentManage',
    component: () =>
      import(/* webpackChunkName: "rental" */ './PaymentManage.vue')
  },
  {
    path: '/rental/cash_pay',
    name: 'MerchantRentalCashPay',
    component: () =>
      import(/* webpackChunkName: "rental" */ './CashPayment.vue')
  },
  {
    path: '/report/daily/home_rental_charges',
    name: 'DailyHomeRentalCharges',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/DailyHomeRentalCharges.vue'
      )
  },
  {
    path: '/report/daily/income_water',
    name: 'DailyIncomeOfWater',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/DailyIncomeOfWater.vue'
      )
  },
  {
    path: '/report/daily/income_develop_secuity',
    name: 'DailyIncomeOfDevelopAndSecuity',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/DailyIncomeOfDevelopAndSecuity.vue'
      )
  },
  {
    path: '/report/monthly/rental_charges',
    name: 'MothlyRentalCharges',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/MothlyRentalCharges.vue'
      )
  },
  {
    path: '/report/monthly/income_others',
    name: 'MothlyIncomeOfOthers',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/MothlyIncomeOfOthers.vue'
      )
  },
  {
    path: '/report/monthly/balance_due_home_rental',
    name: 'MothlyBalanceDueListForHomeRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/MothlyBalanceDueListForHomeRental.vue'
      )
  },
  {
    path: '/report/daily/income_shop_rental',
    name: 'DailyIncomeOfShopRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/DailyIncomeOfShopRental.vue'
      )
  },
  {
    path: '/report/daily/other_income_shop',
    name: 'DailyOtherChargesIncomeOfShopRenter',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/DailyOtherChargesIncomeOfShopRenter.vue'
      )
  },
  {
    path: '/report/monthly/income_shop_renter',
    name: 'MothlyIncomeOfShopRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/MothlyIncomeOfShopRental.vue'
      )
  },
  {
    path: '/report/monthly/other_charges_income',
    name: 'MothlyOtherChargesIcome',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/MothlyOtherChargesIcome.vue'
      )
  },
  {
    path: '/report/monthly/due_list_shop',
    name: 'MothlyDueListForShopRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ '@/projects/operation/views/Billing/Rental/Report/Mandalay/MothlyDueListForShopRental.vue'
      )
  },

  ...BillingReportYangon,
  ...BillingReportNapPyiTaw
]
