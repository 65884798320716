export default [
  {
    name: 'coupon_management ',
    path: '/coupon',
    component: () =>
      import(/* webpackChunkName: "coupon_management " */ './index.vue')
  },
  {
    name: 'couponApplied',
    path: '/coupon/couponApplied',
    component: () =>
      import(
        /* webpackChunkName: "coupon_management " */ '@/projects/operation/views/CouponList/CouponApplied.vue'
      )
  },
  {
    name: 'couponUsed',
    path: '/coupon/couponUsed',
    component: () =>
      import(
        /* webpackChunkName: "coupon_management " */ '@/projects/operation/views/CouponList/CouponUsed.vue'
      )
  },
  {
    name: 'couponRelease',
    path: '/coupon/release',
    component: () =>
      import(/* webpackChunkName: "coupon_management " */ './CouponRelease.vue')
  },
  {
    name: 'couponStatistics',
    path: '/coupon/couponStatistics',
    component: () =>
      import(
        /* webpackChunkName: "campaignStatistics" */ '@/projects/operation/views/CouponList/statistics.vue'
      )
  }
]
