export default [
  {
    name: 'mysell',
    path: '/my_sell',
    component: () => import(/* webpackChunkName: "mysell" */ './index.vue')
  },
  {
    name: 'restaurant-order',
    path: '/r_order',
    component: () =>
      import(
        /* webpackChunkName: "mysell" */ '../Restaurant/RestaurantOrder.vue'
      )
  }
]
