export default [
  {
    name: 'account_history',
    path: 'account_history',
    component: () =>
      import(/* webpackChunkName: "account_history" */ './index.vue')
  },
  {
    name: 'account_list',
    path: '/account_list',
    component: () =>
      import(/* webpackChunkName: "account_list" */ './AccountList.vue')
  },
  {
    name: 'fast_transfer',
    path: '/fast_transfer',
    meta: {
      auth: false
    },
    component: () =>
      import(/* webpackChunkName: "fast_transfer" */ './fastTransfer.vue')
  }
]
