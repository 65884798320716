export default [
  {
    name: 'commodity',
    path: 'commodity',
    component: () => import(/* webpackChunkName: "commodity" */ './index.vue')
  },
  {
    name: 'commodity_release',
    path: '/commodity/release',
    component: () =>
      import(/* webpackChunkName: "commodity" */ './ReleaseList.vue')
  }
]
