export default [
  {
    name: 'food_management',
    path: '/food_management',
    component: () =>
      import(/* webpackChunkName: "restaurant" */ './FoodManagement.vue')
  },
  {
    name: 'table_management',
    path: '/table_management',
    component: () =>
      import(/* webpackChunkName: "restaurant" */ './TableManagement.vue')
  },
  {
    name: 'dines_management',
    path: '/dines_management',
    component: () =>
      import(/* webpackChunkName: "restaurant" */ './DinesManagement.vue')
  },
  {
    name: 'dines_detail',
    path: '/dines_management/detail',
    component: () =>
      import(/* webpackChunkName: "restaurant" */ './DinesDetail.vue')
  }
]
