export default [
  {
    path: '/salary_agency',
    name: 'SalaryAgency',
    component: () =>
      import(
        /* webpackChunkName: "salary_agency" */ '@/projects/operation/views/SalaryAgency/SalaryAgency.vue'
      )
  },
  {
    path: '/salary_agency/detail',
    name: 'SalaryAgencyDetail',
    component: () =>
      import(
        /* webpackChunkName: "salary_agency" */ '@/projects/operation/views/SalaryAgency/SalaryAgencyDetail.vue'
      )
  }
]
